<template>
  <a v-bind:href="href" v-on:click="go"><slot /></a>
</template>

<script>
export default {
  name: "VLink",
  props: ["section", "path"],
  computed: {
    path_list() {
      if (this.path === undefined) return [];

      let list = this.path.split("/");
      if (list[0] == "") list = list.slice(1);

      return list;
    },
    href() {
      let section = this.section;
      if (section === undefined)
        section = this.apis.navigation.getLocation().key;

      let url = "/" + section;
      if (this.path_list.length > 0) url += "/" + this.path_list.join("/");

      return url;
    },
  },
  methods: {
    go(event) {
      event.preventDefault();
      this.navigation.navigate(this.section, this.path_list);
    },
  },
  emits: ["navigation"],
};
</script>

<style scoped></style>
