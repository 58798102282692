<template>
  <div class="text-general row">
    <div class="col-md-6 float-left">
      <h1>404</h1>

      <p class="lead mb-5">Page not found :(</p>

      <v-link class="btn btn-lg btn-primary" section="index">
        Go to start page
      </v-link>
    </div>
    <img
      :src="`${publicPath}error.png`"
      class="float-right light-sheme-only"
      alt="error"
    />
  </div>
</template>

<script>
import VLink from "@/navigation/VLink";
export default {
  name: "NotFound",
  components: { VLink },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped></style>
